import { HttpLink, from } from '@apollo/client'
import {
  ApolloClient,
  InMemoryCache,
  // eslint-disable-next-line import/no-unresolved -- @apollo/experimental-nextjs-app-support provides the exports setting which is not supported by eslint-plugin-import
} from '@apollo/experimental-nextjs-app-support'
import introspection from '@shared/graphql/generated/introspection.json'
import { withScalars } from 'apollo-link-scalars'
import deepmerge from 'deepmerge'
import { buildClientSchema } from 'graphql'
import {
  DateTimeResolver,
  JSONResolver,
  URLResolver,
  UUIDResolver,
  VoidResolver,
} from 'graphql-scalars'
import isEqual from 'lodash.isequal'

import { cacheConfig } from './cache'

import type {
  HttpOptions,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client'
import type { IntrospectionQuery } from 'graphql'
import type { NextRequest } from 'next/server'

export type { ApolloError } from '@apollo/client'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

interface ApolloClientContext {
  /**
   * Next Request.
   */
  req: NextRequest
}

/**
 * Create Apollo Client Instance.
 */
function createApolloClient(ctx?: ApolloClientContext, rsc = false) {
  // Check for server-side
  const ssrMode = typeof window === 'undefined'

  // We want to use a relative path in the browser and an absolute one on the server
  const baseUrl = ssrMode ? 'http://localhost:8090' : ''

  // Default options
  const linkOptions: HttpOptions = {
    uri: `${baseUrl}/api/graphql`,
    credentials: 'include',
    // Apollo recommends passing this header to protect against CSRF attacks.
    headers: { 'Apollo-Require-Preflight': 'true' },
    fetchOptions: rsc ? { cache: 'no-store' } : null,
  }

  const cookie = ctx?.req.headers.get('cookie')
  // pass cookies for the server instance
  if (cookie) {
    linkOptions.headers = {
      cookie,
    }
  }

  const httpLink = new HttpLink(linkOptions)

  const links: ApolloLink[] = [
    withScalars({
      schema: buildClientSchema(introspection as unknown as IntrospectionQuery),
      typesMap: {
        Uuid: UUIDResolver,
        DateTime: DateTimeResolver,
        Url: URLResolver,
        Void: VoidResolver,
        JSON: JSONResolver,
      },
    }),
    httpLink,
  ]

  return new ApolloClient({
    link: from(links),
    cache: new InMemoryCache(cacheConfig),
  })
}

/**
 * Initialize Apollo Client.
 */
function initializeApollo(
  ctx?: ApolloClientContext,
  initialState: NormalizedCacheObject | null = null,
  rsc = false,
) {
  // On the server `apolloClient` is null, and it falls back to createApolloClient(ctx) for each request (new instance)
  // On the client `apolloClient` is null for the first time and never null again during the same session (singleton)
  const _apolloClientRef = apolloClient ?? createApolloClient(ctx, rsc)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here, in most cases it's the cache coming from the server
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClientRef.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = deepmerge<NormalizedCacheObject>(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (
        destinationArray: NormalizedCacheObject[],
        sourceArray: NormalizedCacheObject[],
      ) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClientRef.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClientRef

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClientRef

  return _apolloClientRef
}

/**
 * Initialize Apollo Instance.
 */
export function initApolloClient(
  initialState: NormalizedCacheObject | null = null,
  rsc = false,
) {
  return initializeApollo(undefined, initialState, rsc)
}

/**
 * Create Instance for Graphql-Codegen.
 */
export function getApolloClient(ctx?: ApolloClientContext) {
  return initializeApollo(ctx)
}
