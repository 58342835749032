import type React from 'react'

import { DatadogRumProvider } from '@circlefin/datadog/react/context/provider'

import { DatadogRum } from './DatadogRum/DatadogRum'
import { getSessionReplaySampleRate } from './getSessionReplaySampleRate/getSessionReplaySampleRate'

export interface DatadogProviderProps {
  /**
   * App version.
   */
  version: string
  /**
   * App id.
   */
  applicationId?: string | null
  /**
   * App token.
   */
  clientToken?: string | null
  /**
   * Environment the app is running in.
   */
  env: string
  /**
   * Service name.
   */
  service: string
}

/**
 * DatadogProvider component that sets up Datadog RUM (Real User Monitoring).
 * It wraps the DatadogRumProvider with specific configuration and includes the DatadogRum component.
 * @param props - The props for configuring Datadog RUM.
 * @returns The DatadogProvider component.
 */
export const DatadogProvider: React.FC<DatadogProviderProps> = ({
  env,
  service,
  version,
  applicationId,
  clientToken,
}) => {
  if (!applicationId || !clientToken) return null

  return (
    <DatadogRumProvider
      applicationId={applicationId}
      clientToken={clientToken}
      env={env}
      overwriteConfig={{
        sessionReplaySampleRate: getSessionReplaySampleRate(
          window.location.hostname,
        ),
        trackViewsManually: true,
      }}
      privacyLevel="mask-user-input"
      service={service}
      version={version}
      enableFeatureFlagTracking
    >
      <DatadogRum />
    </DatadogRumProvider>
  )
}
