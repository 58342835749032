/**
 * Determines the session replay sample rate based on the provided hostname.
 * @returns The session replay sample rate as a number.
 */
export const getSessionReplaySampleRate = (hostname?: string) => {
  // Ensure we're in a browser environment
  if (typeof hostname === 'undefined') {
    return 0
  }

  if (hostname.startsWith('faucet')) return 5
  if (hostname.startsWith('usdc-finder')) return 100

  return 50
}
